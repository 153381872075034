var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('legal-person-create-wizard', {
    ref: "legalWizard",
    attrs: {
      "error-messages": _vm.legalPersonWizardErrors
    },
    on: {
      "submit": _vm.createLegalPerson
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }