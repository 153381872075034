<template>
  <div class="card card-custom">
    <div class="card-body">
      <legal-person-create-wizard ref="legalWizard" :error-messages="legalPersonWizardErrors"
        @submit="createLegalPerson"></legal-person-create-wizard>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import LegalPersonCreateWizard from "@/view/components/wizards/LegalPersonCreateWizard.vue";
import CompanyService from "@/core/services/company/company.service";
import PhysicalPersonService from "@/core/services/physical-person/physical-person.service";

import Swal from "sweetalert2";
import { backendErrorSwal } from "@/core/helpers/swal";

export default {
  components: {
    LegalPersonCreateWizard,
  },

  data() {
    return {
      legalPersonWizardErrors: {},
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Legal person") },
      { title: this.$t("Create") },
    ]);
  },

  methods: {
    async createLegalPerson(form) {
      if (form.physical_person_contact_list?.id) {
        const id = form.physical_person_contact_list.id;
        form.physical_person_contact_list = [
          {
            role: form.physical_person_contact_list.role,
            physicalperson: id,
          },
        ];
      } else if (form.physical_person_contact_list?.name) {
        delete form.physical_person_contact_list.roleName;
        const res = await PhysicalPersonService.create(form.physical_person_contact_list);
        form.physical_person_contact_list = [
          {
            physicalperson: res.id,
            role: form.physical_person_contact_list.role,
          },
        ];
      }
      try {
        await CompanyService.create(form);
        Swal.fire("Success", "Legal Person created successfully", "success");
        this.$router.push({ name: "legal-person" });
      } catch (err) {
        this.$refs.legalWizard.loading = false;
        console.log(err);
        if (err?.response?.status === 400) {
          this.legalPersonWizardErrors = err.response.data;
          const fields = Object.keys(err.response.data);
          const errors = Object.keys(err.response.data[fields[0]][0]);
          const msg = err.response.data[fields[0]][0][errors[0]][0];

          Swal.fire("Error", msg, "error");
        } else {
          backendErrorSwal(err, err?.response?.data?.detail);
        }
      }
    },
  },
};
</script>
